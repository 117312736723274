<script setup>
import { useToast } from "vue-toastification";
import c3api from "../../c3api";

const props = defineProps({
  params: Object,
});
const toast = useToast();

let canDelete = props.params.data?.workflow_state != "complete";
const deleteInvoice = async () => {
  let params = props.params;
  const invoiceId = params.data.id;
  const url = `/order_invoices/${invoiceId}`;

  await c3api
    .delete(url, {})
    .then((response) => {
      console.log("Response Data:", response);
      params.api.refreshServerSide();
      toast.success("Invoice has been successfully removed.");
    })
    .catch((error) => {
      toast.error(error.response?.data.data.join(". "));
    });
};
</script>

<template>
  <v-btn
    v-if="canDelete"
    icon="mdi-delete"
    variant="text"
    @click="deleteInvoice()"
  ></v-btn>
</template>

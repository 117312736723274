<script setup>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.min.css";
import { ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import c3api from "../../c3api";
import {
  agGridformatCurrency,
  workflowStateValueFormatter,
  clientValueFormatter,
} from "@/utils/dataUtils";
import { useToast } from "vue-toastification";
import {
  saveGridState,
  loadGridState,
  addPrefixToId,
  resetGridColState,
  resetGridFilterState,
} from "@/utils/agGridUtils";
import { useRouter } from "vue-router";
import InvoiceDeleteCellRenderer from "@/components/billings/InvoiceDeleteCellRenderer.vue";
import BillingClientFilter from "../../components/ag-filters/BillingClientFilter.vue";
import { useThemeStore } from "@/stores/themeStore";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(import.meta.env.VITE_AG_GRID_LICENSE_KEY);

const themeStore = useThemeStore();
const router = useRouter();
const INVOICE_COMPLETE = "complete";
const loadCount = ref(0);
const toast = useToast();
const GRID_SAVE_KEY = "invoiceDetails";
const context = ref(null);
let gridApi;

const onGridReady = (params) => {
  gridApi = params.api;
  addFuncsToColDefs();
  context.value = {
    onBillSaveCallback,
  };
  updateColumnDefs();
  loadGridState(GRID_SAVE_KEY, gridApi, colDefs.value);
};

const onBillSaveCallback = () => {
  gridApi.refreshServerSide(); // Ensure no unnecessary purging
};

const updateColumnDefs = () => {
  let currColDefs = gridApi.getColumnDefs();
  currColDefs = currColDefs.filter((item) => item.colId !== "action");
  currColDefs.push({
    field: "action",
    filter: null,
    headerName: "",
    colId: "action",
    cellRenderer: InvoiceDeleteCellRenderer,
    width: 135,
  });
  gridApi.setGridOption("columnDefs", currColDefs);
};

const defaultColDef = ref({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  suppressMenu: true,
  resizable: true,
  width: 120,
  filterParams: {
    maxNumConditions: 1,
    closeOnApply: true,
    filterOptions: ["contains"],
    buttons: ["apply"],
  },
});

const valueGetters = {
  id: (params) => {
    return addPrefixToId("I", params.data?.id);
  },
  created_at: (params) => params.data?.created_at?.substring(0, 10),
};

const advancedFilterKeys = [
  "id",
  "invoice_total",
  "created_at",
  "client.name",
  "workflow_state",
  "created_by.name",
];

const buildQueryParams = function (params) {
  const urlParams = {
    sortModel: params.sortModel,
    filterModel: params.filterModel,
    startRow: params.startRow,
    endRow: params.endRow,
    batch_size: 100,
    limit: 400,
  };

  const filterParams = {};
  for (let [k, v] of Object.entries(params.filterModel)) {
    if (advancedFilterKeys.includes(k)) {
      if (v.filterType === "set") {
        filterParams[k] = {
          filter: v.values,
          type: v.filterType,
        };
      } else {
        filterParams[k] = v;
      }
    } else {
      urlParams[k] = v.filter || v.dateFrom?.substring(0, 10);
    }
  }
  urlParams.filter = filterParams;

  if (typeof params.startRow !== "undefined") {
    urlParams.start_row = params.startRow;
    urlParams.limit = params.endRow - params.startRow;
  }
  return urlParams;
};

const connectDatasource = {
  getRows: async (params) => {
    loadCount.value++;

    try {
      const urlParams = buildQueryParams(params.request);
      gridApi.showLoadingOverlay();
      const response = await c3api.get("/order_invoices", { params: urlParams });
      const data = response.data;

      // Using params.success to pass the data to the grid
      params.success({
        rowData: data.data,
        rowCount: data.total_count,
      });
    } catch (error) {
      console.error(error);
      toast.error(error.response.data.data.join(". "));
      params.fail();
    } finally {
      loadCount.value--;
      if (loadCount.value <= 0) {
        gridApi.hideOverlay();
      }
    }
  },
};

const onStateUpdated = function (params) {
  saveGridState(GRID_SAVE_KEY, gridApi);
};

const dateFilterParams = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange", "blank"],
  includeBlanksInLessThan: true,
  inRangeInclusive: true,
};

const numberFilters = {
  filterOptions: ["equals", "greaterThanOrEqual", "lessThanOrEqual", "inRange"],
  inRangeInclusive: true,
};

const colDefs = ref([
  {
    field: "id",
    colId: "id",
    sort: "desc",
    width: 200,
    headerName: "Invoice #",
    cellDataType: "text",
  },
  {
    field: "client.name",
    colId: "client.name",
    headerName: "Client",
    cellDataType: "text",
    width: 200,
    filter: "agMultiColumnFilter",
    filterParams: {
      filters: [
        {
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains"],
          },
        },
        {
          filter: BillingClientFilter,
          filterParams: {
            valueGetter: (params) => params.data.client.name,
          },
          valueFormatter: clientValueFormatter,
        },
      ],
    },
  },
  {
    field: "created_by.name",
    colId: "created_by.name",
    headerName: "Created By",
    cellDataType: "text",
    width: 180,
  },
  {
    field: "workflow_state",
    headerName: "Status",
    cellDataType: "text",
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["draft", "complete"],
    },
    width: 135,
    valueFormatter: workflowStateValueFormatter,
  },
  {
    field: "invoice_total",
    headerName: "Total Amount",
    cellDataType: "number",
    type: "rightAligned",
    width: 135,
    filter: "agNumberColumnFilter",
    filterParams: numberFilters,
    valueFormatter: agGridformatCurrency,
  },
  {
    field: "created_at",
    colId: "created_at",
    cellDataType: "dateString",
    headerName: "Created At",
    cellDataType: "dateString",
    width: 135,
    filter: "agDateColumnFilter",
    filterParams: dateFilterParams,
  },
  {
    field: "action",
    filter: null,
    headerName: "",
    colId: "action",
    cellRenderer: InvoiceDeleteCellRenderer,
    width: 135,
  },
]);

const addFuncsToColDefs = function () {
  for (let def of colDefs.value) {
    let colName = def.field;
    if (valueGetters[colName]) {
      def.valueGetter = valueGetters[colName];
    }
  }
};

addFuncsToColDefs();

const gridOptions = {
  masterDetail: true,
  rowModelType: "serverSide",
  serverSideDatasource: connectDatasource,
  rowSelection: "multiple",
  cacheBlockSize: 100,
  blockLoadDebounceMillis: 500,
  getRowId: (params) => params.data.id,
  sideBar: {
    toolPanels: [
      {
        id: "columns",
        labelDefault: "Columns",
        labelKey: "columns",
        iconKey: "columns",
        toolPanel: "agColumnsToolPanel",
        toolPanelParams: {
          suppressRowGroups: true,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
      },
    ],
  },
  onSortChanged: (event) => {
    console.log("Sort Changed Event:", event);
  },
  onStateChanged: () => {
    saveGridState(GRID_SAVE_KEY, gridApi);
  },
  onFirstDataRendered: (params) => {
    loadGridState(GRID_SAVE_KEY, params.api, colDefs.value);
  },
};

const rowDoubleClicked = function (event) {
  if (event.data.id) {
    gridApi.showLoadingOverlay();
    window.location = `/invoices/${event.data.id}`;
  } else {
    console.log("Row clicked does not have an event.data.id!");
    console.log(event);
  }
};

const resetGridFilterSettings = () => {
  resetGridFilterState(GRID_SAVE_KEY, gridApi);
  router.go();
};
const resetGridColSettings = () => {
  resetGridColState(GRID_SAVE_KEY, gridApi);
  router.go();
};
</script>

<template>
  <div id="invoices-orders-wrapper" class="d-flex flex-wrap" style="height: 94%">
    <div class="d-flex justify-end w-100">
      <div class="d-flex-end justify-end ml-auto mr-2">
        <v-btn class="mb-2" variant="text" @click="resetGridFilterSettings"
          >Reset Filters</v-btn
        >
        <v-btn class="mb-2" variant="text" @click="resetGridColSettings"
          >Reset Columns</v-btn
        >
      </div>
    </div>
    <AgGridVue
      style="width: 100%; height: 100%"
      :class="themeStore.agTheme"
      :columnDefs="colDefs"
      :defaultColDef="defaultColDef"
      :gridOptions="gridOptions"
      @grid-ready="onGridReady"
      @rowDoubleClicked="rowDoubleClicked"
      @state-updated="onStateUpdated"
    ></AgGridVue>
  </div>
</template>

<style scoped>
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}

.ag-icon {
  display: flex !important;
}

.filterToggle {
  margin-left: 15px;
}

.filter {
  display: none;
}

.select-component {
  width: 15%;
  margin-top: 25px;
  margin-left: 15px;
}

#invoices-orders-wrapper {
  width: 100%;
  height: 100%; /* Ensure it takes full height */
  padding: 0; /* Remove padding to ensure full height utilization */
  box-sizing: border-box;
}
</style>
